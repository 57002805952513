import * as yup from 'yup'
import { parametersKeys, rythmOptions } from './formVars'
import { getSexValue } from './misc'
import { without } from 'ramda'

const DEFAULT_REQUIRED_MSG = 'Campo requerido'

const baseValidator = {
  centerInfo: yup.number().required(`${DEFAULT_REQUIRED_MSG}: Centro`),
  names: yup.string().required('Nombres son requeridos'),
  lastNames: yup.string().required('Apellidos son requeridos'),
  birthDay: yup.number('').nullable().max(31).min(1).required(`${DEFAULT_REQUIRED_MSG}: Día de nacimiento`),
  birthMonth: yup.number('').nullable().max(12).min(1).required(`${DEFAULT_REQUIRED_MSG}: Mes de nacimiento`),
  birthYear: yup.number('').nullable().min(1900).required(`${DEFAULT_REQUIRED_MSG}: Año de nacimiento`),
  lastECGDay: yup.number().max(31).min(1).nullable(),
  lastECGMonth: yup.number().max(12).min(1).nullable(),
  lastECGYear: yup.number().min(1900).nullable(),
  sex: yup.string('').nullable().oneOf(['MASCULINO', 'FEMENINO']).required(`${DEFAULT_REQUIRED_MSG}: Sexo`),
  tas: yup.number().nullable().required(`${DEFAULT_REQUIRED_MSG}: TAS`),
  tad: yup.number().nullable().required(`${DEFAULT_REQUIRED_MSG}: TAD`),
  height: yup.number().nullable().required(`${DEFAULT_REQUIRED_MSG}: Estatura`),
  weight: yup.number().nullable().required(`${DEFAULT_REQUIRED_MSG}: Peso`),
  email: yup.string().email('Ingrese un email de paciente válido'),
  smoker: yup.bool(),
  alcoholic: yup.bool(),
  diabetic: yup.bool(),
  hypertensive: yup.bool(),
  previousSurgery: yup.bool(),
  transfusions: yup.bool(),
  hepatitis: yup.bool(),
  heartDiseaseHistory: yup.bool(),
  cholesterol: yup.bool(),
  previousEcg: yup.bool(),
  resultNormal: yup.bool(),
  urgency: yup.bool(),
  redCode: yup.bool(),
  rejected: yup.bool(),
  accepted: yup.bool(),
  withInterpretation: yup.bool(),
  thirdAuthorization: yup.bool(),
  thirdEmail: yup.string().email('Ingrese un email de tercero válido'),
  pacemaker: yup.bool(),
  pacemakerKind: yup.string(),
  checkReason: yup.string()
    .oneOf(
      [
        'CHEQUEO',
        'PREOPERATORIO',
        'REVISIÓN GENERAL',
        'DOLOR PRECORDIAL',
        'DOLOR ENFERMEDAD CARDIACA',
      ],
    ).required(`${DEFAULT_REQUIRED_MSG}: Motivo`),
  thirdName: yup.string().nullable(),
  relationship: yup.string().nullable(),
  notes: yup.string(),
  diagnosis: yup.string(),
  studioId: yup.string().nullable().required(`${DEFAULT_REQUIRED_MSG}: Folio`),
  isReported: yup.bool(),
  hasTrace: yup.bool(),
  rythm: yup.string().oneOf(rythmOptions),
  frequency: yup.number(),
  frequencyUnit: yup.string(),
  pAxis: yup.number(),
  qrsAxis: yup.number(),
  tAxis: yup.number(),
  rR: yup.number(),
  pR: yup.number(),
  qrs: yup.number(),
  qtm: yup.number(),
  qtc: yup.number(),
  stT: yup.number(),
  isResponsible: yup.string(),
  isNew: yup.bool(),
  patient: yup.number().nullable().required(`${DEFAULT_REQUIRED_MSG}: Paciente`),
}

const createConsenNewPatientKeys = [
  'centerInfo',
  'names',
  'lastNames',
  'birthDay',
  'birthMonth',
  'birthYear',
  'sex',
  'tas',
  'tad',
  'height',
  'weight',
  'email',
  'pacemaker',
  'pacemakerKind',
  'lastECGDay',
  'lastECGMonth',
  'lastECGYear',
  'resultNormal',
  'checkReason',
  'thirdAuthorization',
  'thirdEmail',
  'thirdName',
  'relationship',
  'withInterpretation',
  'notes',
  'isResponsible',
  'studioId',
  'isNew',
]

const createFromExistingPatientKeys = [
  ...without(['names', 'lastNames', 'birthDay', 'birthMonth', 'birthYear', 'sex'], createConsenNewPatientKeys),
  'patient',
]

export const getConsentFormValidation = isNew => yup.object().shape(Object.fromEntries(
  Object.entries(baseValidator).filter(([key]) => isNew ? createConsenNewPatientKeys.includes(key) : createFromExistingPatientKeys.includes(key)),
))

export const chewAPIEcgDataToForm = ecg => {
  if (!ecg) return
  const chewedData = {...ecg}
  const birthDate = new Date(Date.parse(ecg.patient.birthDate))
  chewedData.age = ecg.patient.age
  delete chewedData.patient
  chewedData.name = ecg.patient.name
  chewedData.email = ecg.patient.email
  chewedData.thirdEmail = ecg.patient.thirdEmail
  chewedData.sex = getSexValue(ecg.patient.sex)
  chewedData.patient = ecg.patient.id
  chewedData.birthDay = birthDate.getUTCDate()
  chewedData.birthMonth = birthDate.getUTCMonth() + 1
  chewedData.birthYear = birthDate.getUTCFullYear()
  chewedData.checkReason = ecg.checkReason ? ecg.checkReason.toUpperCase() : ''
  if (ecg.previousEcg) {
    const lastEcgDate = new Date(Date.parse(ecg.lastEcgDate))
    chewedData.lastECGDay = lastEcgDate.getUTCDate()
    chewedData.lastECGMonth = lastEcgDate.getUTCMonth() + 1
    chewedData.lastECGYear = lastEcgDate.getUTCFullYear()
  }
  const [tas, tad] = ecg.bloodPressure.split('/')
  chewedData.tas = tas
  chewedData.tad = tad
  parametersKeys.forEach(key => {
    if (chewedData[key] === -9999) chewedData[key] = '-'
  })
  chewedData.discountTag = ecg.discountTag?.id ?? null

  return chewedData
}
